import React, { useContext } from 'react';
import Menu from '../components/Navbar';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import './css/P_ChoosePaymentMethod.css';
import { useNavigate, useLocation } from 'react-router-dom';
import MyToast from '../components/MyToast';
import MySpinner from '../components/MySpinner';
import { generateUniqueMongoDbID, saveSubscription, sendMailWithNodemailer } from '../services/api';
import { AppConfigurationContext } from '../Contexts/AppConfigContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';


const P_ChoosePaymentMethod = () => {

    const location = useLocation();
    const navigate = useNavigate();

    let offer = location.state?.offer || null;

    const appConfig = useContext(AppConfigurationContext);
    const [user, setUser] = React.useState(null);
    const [offerData, setOfferData] = React.useState(null);
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setUser(authUser)
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);


    React.useEffect(() => {

        if (!offer) {
            navigate('/error');
            return;
        }
        else {
            let tmp = JSON.parse(offer);
            setOfferData(tmp);
        }

    }, [offer, navigate])


    const navigateToHome = () => {
        navigate('/accueil')
    }


    const handleCartebancaire = async () => {

        setinProgress(true);

        try {

            const startDate = new Date();
            const paymentID = (await generateUniqueMongoDbID()).data.data;

            //enregistrement de la souscription
            const subscription = await saveSubscription({
                offerID: offerData._id,
                userID: user.uid,
                startDate: startDate.toISOString(),
                endDate: new Date(startDate.getTime() + parseInt(offerData.duration) * 24 * 60 * 60 * 1000).toISOString(),
                subscriptionStatus: 'PENDING'
            });

            const subscriptionID = subscription.data.data?._id;

            setinProgress(false);

            let link = null;

            if (appConfig.payment_links && offerData.name == "OR") {
                link = appConfig.payment_links.or;
            }
            if (appConfig.payment_links && offerData.name == "Argent") {
                link = appConfig.payment_links.argent;
            }
            if (appConfig.payment_links && offerData.name == "Bronze") {
                link = appConfig.payment_links.bronze;
            }

            if (link) {

                try {
                    window.open(link, '_blank');
                    navigateToHome();
                } catch (err) {
                    setOpenToast({
                        open: true,
                        msg: "Une erreur s'est produite lors de l'ouverture du lien de paiement. Veuillez nous contacter sur Whatsapp si l'erreur persiste.",
                        severity: 'error'
                    })

                    return;
                }

                sendMailWithNodemailer({
                    from: 'services@exams-tcfcanada.com',
                    to_email: `examstcfcanada@gmail.com`,
                    from_name: 'EXAMS TCF CANADA',
                    subject: "DEBUT DE PAIEMENT AVEC SQUARE WEB",
                    text: `Un utilisateur vient de cliquer sur payer avec carte via Square dans l'app mobile. Les détails sont les suivants:\n\nEmail: ${user.email}\nOffre: ${offerData?.name}\nPaymentID: ${paymentID}\nSubscriptionID: ${subscriptionID}`
                });
            }

            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite lors au cours de l'opération. Veuillez nous contacter sur Whatsapp si l'erreur persiste.",
                    severity: 'error'
                })

                return;
            }

        } catch (error) {

            setinProgress(false);

            setOpenToast({
                open: true,
                msg: "Une erreur s'est produite lors au cours de l'opération. Veuillez nous contacter sur Whatsapp si l'erreur persiste.",
                severity: 'error'
            })

            return;
        }
    }


    const handleMobilePayment = () => {
        navigate(`/subscription`, {
            state: {
                offer: offer
            }
        });
    }


    return (
        <>
            <Menu />

            <div style={{ marginTop: '9rem' }}></div>

            <Container className="payment-method-container col-12 col-md-10 col-xl-8">
                <div className="payment-method-notice">
                    <strong>Importante:</strong> Si vous rencontrez des difficultés au cours du paiement, veuillez nous contacter via WhatsApp en cliquant le bouton Support ou par mail (examstcfcanada@gmail.com).
                </div>

                <h2 className="payment-method-title">Choisissez votre méthode de paiement</h2>

                <Row className="payment-options-row">
                    <Col md={5} xl={5} className="payment-option" onClick={handleMobilePayment}>
                        <div className="payment-card">
                            <img
                                src='../assets/img/mobile1.png'
                                alt="Paiement Mobile"
                                className="payment-card-image"
                            />
                            <div className="payment-card-label">Paiement Mobile</div>
                        </div>
                    </Col>
                    <Col md={5} xl={5} className="payment-option" onClick={handleCartebancaire}>
                        <div className="payment-card">
                            <img
                                src='../assets/img/card.png'
                                alt="Carte Bancaire"
                                className="payment-card-image"
                            />
                            <div className="payment-card-label">Carte Bancaire</div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div style={{ marginTop: '2rem' }}></div>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

            <Footer />
        </>
    );
};

export default P_ChoosePaymentMethod;
