import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../Contexts/AuthContext';

// Icons
import CalculateIcon from '@mui/icons-material/Calculate';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GetAppIcon from '@mui/icons-material/GetApp';

const Cover = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const scrollToElement = () => {
        const element = document.getElementById('start');
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const typingSequence = [
        'Sujets de Compréhension Orale',
        1500,
        'Sujets de Compréhension Écrite',
        1500,
        'Sujets d\'Expression Orale',
        1500,
        'Sujets d\'Expression Écrite',
        1500
    ];

    return (
        <div
            className="cover-section position-relative"
            style={{
                backgroundColor: "#2c2a28",
                backgroundImage: "linear-gradient(135deg, #2c2a28 0%, #3a3835 100%)",
                padding: "6rem 0",
                overflow: "hidden"
            }}
        >
            {/* Background decorative elements */}
            <div
                className="position-absolute"
                style={{
                    top: '10%',
                    left: '5%',
                    width: '20rem',
                    height: '20rem',
                    background: 'radial-gradient(circle, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 70%)',
                    borderRadius: '50%',
                    zIndex: 1
                }}
            />
            <div
                className="position-absolute"
                style={{
                    bottom: '5%',
                    right: '10%',
                    width: '15rem',
                    height: '15rem',
                    background: 'radial-gradient(circle, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0) 70%)',
                    borderRadius: '50%',
                    zIndex: 1
                }}
            />

            <div className="container position-relative" style={{ zIndex: 2 }}>
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8 text-center">
                        {/* Main heading with highlight effect */}
                        <h1
                            className="text-white mb-4 display-4"
                            style={{
                                fontWeight: 300,
                                textShadow: '0 2px 4px rgba(0,0,0,0.3)'
                            }}
                        >
                            <span
                                className="d-inline-block position-relative"
                                style={{
                                    fontWeight: 700,
                                    backgroundImage: 'linear-gradient(45deg, #4da6ff, #47d8e0)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    paddingBottom: '5px'
                                }}
                            >
                                Préparation au TCF Canada
                                <span
                                    className="position-absolute"
                                    style={{
                                        height: '3px',
                                        width: '100%',
                                        background: 'linear-gradient(90deg, transparent, #4da6ff, #47d8e0, transparent)',
                                        bottom: 0,
                                        left: 0
                                    }}
                                />
                            </span>
                        </h1>

                        {/* Subheading with better typography */}
                        <p
                            className="lead text-white mb-5"
                            style={{
                                opacity: 0.8,
                                maxWidth: '80%',
                                margin: '0 auto',
                                fontSize: '1.1rem',
                                lineHeight: 1.6
                            }}
                        >
                            Plateforme spécialisée dans la préparation du TCF Canada.
                            <br />
                            <span className="font-weight-light">Des tests en conditions réelles pour maximiser vos chances de réussite.</span>
                        </p>

                        {/* Primary action buttons with improved styling */}
                        <div className="d-flex flex-wrap justify-content-center mb-5">
                            <Button
                                variant="outline-info"
                                className="px-4 py-2 mx-2 mb-3"
                                onClick={scrollToElement}
                                style={{
                                    borderWidth: '2px',
                                    borderRadius: '30px',
                                    fontWeight: 600,
                                    minWidth: '160px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <FitnessCenterIcon className="mr-2" style={{ fontSize: '1.1rem' }} />
                                S'entraîner
                            </Button>

                            <Button
                                variant="outline-success"
                                className="px-4 py-2 mx-2 mb-3"
                                onClick={() => navigate('/calculatrice-nclc')}
                                style={{
                                    borderWidth: '2px',
                                    borderRadius: '30px',
                                    fontWeight: 600,
                                    minWidth: '160px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <CalculateIcon className="mr-2" style={{ fontSize: '1.1rem' }} />
                                Calcul NCLC
                            </Button>
                        </div>

                        {/* Animated typing effect with better styling */}
                        <div
                            className="typing-animation-container py-3 px-4 mb-5"
                            style={{
                                background: 'rgba(255,255,255,0.05)',
                                borderRadius: '8px',
                                backdropFilter: 'blur(10px)',
                                display: 'inline-block',
                                boxShadow: 'inset 0 0 10px rgba(0,0,0,0.1)'
                            }}
                        >
                            <TypeAnimation
                                sequence={typingSequence}
                                wrapper="div"
                                speed={30}
                                repeat={Infinity}
                                style={{
                                    color: '#ffca28',
                                    fontWeight: 500,
                                    fontSize: '1.1rem',
                                    textDecoration: 'none',
                                    borderBottom: '1px dashed rgba(255,202,40,0.5)',
                                    paddingBottom: '4px'
                                }}
                            />
                        </div>

                        {/* Secondary action buttons */}
                        <div className="secondary-actions d-flex flex-wrap justify-content-center">
                            {!user && (
                                <Button
                                    variant="danger"
                                    className="px-4 py-2 mx-2 mb-3"
                                    onClick={() => navigate('/inscription')}
                                    style={{
                                        fontWeight: 500,
                                        borderRadius: '6px',
                                        minWidth: '200px',
                                        boxShadow: '0 4px 15px rgba(220, 53, 69, 0.3)',
                                        transition: 'all 0.3s ease'
                                    }}
                                >
                                    <PersonAddIcon className="mr-2" />
                                    M'inscrire gratuitement
                                </Button>
                            )}

                            <Button
                                variant="primary"
                                className="px-4 py-2 mx-2 mb-3"
                                href="https://bit.ly/examstcfcanada"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontWeight: 500,
                                    borderRadius: '6px',
                                    minWidth: '200px',
                                    boxShadow: '0 4px 15px rgba(13, 110, 253, 0.3)',
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <GetAppIcon className="mr-2" />
                                Installer l'application Android
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* CSS for hover effects and animations */}
            <style jsx>{`
        .btn-outline-info:hover, .btn-outline-success:hover {
          transform: translateY(-3px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }
        
        .btn-danger:hover, .btn-primary:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
        }
        
        @media (max-width: 768px) {
          .cover-section {
            padding: 4rem 0;
          }
        }
      `}</style>
        </div>
    );
};

export default Cover;