import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import { DeviseConvContext } from '../Contexts/DeviseConvertContext';
import { listOffers, listSubscriptions } from '../services/api';

// Components
import MyToast from './MyToast';
import MySpinner from './MySpinner';
import QuestionModal from './QuestionModal';

// UI Components
import Spinner from 'react-bootstrap/Spinner';
import Chip from '@mui/material/Chip';

// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

// Pricing plan color themes
const pricingColors = [
    'primary',   // blue
    'success',   // green
    'danger',    // red
    'info',      // light blue
    'warning',   // yellow
    'secondary', // gray
];

// Badge labels for plans (optional)
const pricingBadges = {
    'Standard': 'POPULAIRE',
    'Premium': 'RECOMMANDÉ',
};

const Pricing = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const valDollar = useContext(DeviseConvContext);

    // State
    const [inProgress, setInProgress] = useState(false);
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openToast, setOpenToast] = useState({
        open: false,
        msg: '',
        severity: ''
    });

    // Modal state
    const [show, setShow] = useState(false);
    const [modalValue, setModalValue] = useState('');
    const [question, setQuestion] = useState('');
    const [title, setTitle] = useState('');
    const [newOffer, setNewOffer] = useState(null);

    // Fetch offers on component mount
    useEffect(() => {
        fetchOffers();
    }, []);

    // Handle modal response
    useEffect(() => {
        handleModalResponse();
    }, [modalValue]);

    const fetchOffers = async () => {
        try {
            const response = await listOffers({ status: 'ACTIVED', skip: 0, limit: 10 });
            if (response?.data?.data) {
                setOffers(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching offers:', error);
            setOpenToast({
                open: true,
                msg: 'Impossible de récupérer les offres. Veuillez réessayer ultérieurement.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleModalResponse = () => {
        if (modalValue === 'annuler') {
            resetModalState();
        } else if (modalValue === 'accept') {
            setInProgress(false);
            navigateToPayment();
        }
    };

    const resetModalState = () => {
        setInProgress(false);
        setModalValue('');
        setQuestion('');
        setTitle('');
    };

    const navigateToPayment = () => {
        const params = JSON.stringify(newOffer);
        navigate('/choose-payment-method', {
            state: { offer: params }
        });
    };

    const handleSubscription = async (e, index) => {
        e.preventDefault();

        if (!user) {
            navigate('/connexion', { state: { msg: 'Vous devez vous connecter pour souscrire à cet abonnement' } });
            return;
        }

        setInProgress(true);
        resetModalState();
        setShow(false);

        try {
            // Get selected offer
            const offer = offers[index];
            setNewOffer(offer);

            // Check user's current subscriptions
            const subscriptionsDatas = await listSubscriptions({
                userID: user.uid,
                subscriptionStatus: 'ACTIVED',
                count: true
            });

            if (subscriptionsDatas.data?.data === undefined) {
                setOpenToast({
                    open: true,
                    msg: 'Impossible de souscrire à cette offre pour le moment. Veuillez réessayer ultérieurement',
                    severity: 'error'
                });
                setInProgress(false);
                return;
            }

            const hasActiveSubscription = subscriptionsDatas?.data?.data !== 0;

            if (hasActiveSubscription) {
                setTitle('Nouvel abonnement');
                setQuestion('Votre abonnement actuel est toujours en cours. Si vous cliquez sur Continuer, votre abonnement sera mis à jour avec le nouvel abonnement');
                setShow(true);
            } else {
                setModalValue('accept');
            }
        } catch (error) {
            console.error('Subscription error:', error);
            setOpenToast({
                open: true,
                msg: 'Impossible de souscrire à cette offre pour le moment. Veuillez réessayer ultérieurement',
                severity: 'error'
            });
            setInProgress(false);
        }
    };

    // Helper functions
    const formatQty = (qte) => {
        qte = qte.toString();
        return qte.length === 1 ? `0${qte}` : qte;
    };

    const formatSubject = (descrip, qte) => {
        qte = parseInt(qte);
        const accord = qte > 1 ? 's' : '';
        const sujet = descrip.replace('Epreuve', '');
        return `Sujet${accord} ${sujet}`;
    };

    const convertAmountToCfa = (price) => {
        const conversionRate = parseFloat(valDollar) || 555;
        return parseFloat(price) * conversionRate;
    };

    // Loading state
    if (loading) {
        return (
            <div className="text-center py-5">
                <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    style={{ width: '3rem', height: '3rem' }}
                >
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
                <p className="mt-3 text-muted">Chargement des offres...</p>
            </div>
        );
    }

    return (
        <section className="pricing-section py-5">
            <div className="container">
                <div className="row justify-content-center mb-5">
                    <div className="col-lg-7 text-center">
                        <span className="badge rounded-pill bg-primary-subtle text-primary mb-3">Nos abonnements</span>
                        <h2 className="section-title mb-3">Choisissez l'offre qui vous convient</h2>
                        <p className="text-muted">
                            Nos formules d'abonnement sont conçues pour vous accompagner efficacement dans votre préparation au TCF Canada.
                        </p>
                    </div>
                </div>

                <div className="row g-4 justify-content-center">
                    {offers.map((offer, index) => {
                        const colorClass = pricingColors[index % pricingColors.length];
                        const hasBadge = pricingBadges[offer.name];

                        return (
                            <div key={offer._id} className="col-lg-4 col-md-6">
                                <div className={`pricing-card h-100 ${hasBadge ? 'featured' : ''}`}>
                                    {hasBadge && (
                                        <div className={`popular-badge bg-${colorClass}`}>
                                            {hasBadge}
                                        </div>
                                    )}

                                    <div className={`pricing-header bg-${colorClass}-subtle p-4 text-center`}>
                                        <h3 className="pricing-title fw-bold">{offer.name}</h3>

                                        <div className="pricing-price mt-4">
                                            <div className="d-flex justify-content-center align-items-baseline">
                                                <AttachMoneyIcon className={`text-${colorClass}`} />
                                                <span className={`price-value fw-bold text-${colorClass}`}>{offer.price}</span>
                                                <span className="currency">$ CA</span>
                                            </div>

                                            <div className="price-xaf mt-1">
                                                <LocalAtmIcon style={{ fontSize: '0.9rem' }} className="me-1" />
                                                {convertAmountToCfa(offer.price)?.toLocaleString('fr-FR', {
                                                    style: 'currency',
                                                    currency: 'XAF',
                                                    maximumFractionDigits: 0
                                                })}
                                            </div>

                                            <div className="duration mt-3 d-flex align-items-center justify-content-center">
                                                <AccessTimeIcon style={{ fontSize: '1rem' }} className="me-1" />
                                                <span>Valide {offer.duration} jours</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pricing-features p-4">
                                        <ul className="list-unstyled">
                                            {offer.subjectsTypes.map((subType, i) => (
                                                <li key={i} className="mb-3 d-flex align-items-start">
                                                    <CheckCircleOutlineIcon className={`text-${colorClass} me-2 flex-shrink-0 mt-1`} style={{ fontSize: '1.2rem' }} />
                                                    <span>
                                                        <strong>{formatQty(subType.quantity)}</strong> {formatSubject(subType.subjectType.description, subType.quantity)}
                                                    </span>
                                                </li>
                                            ))}

                                            <li className="mb-3 d-flex align-items-start">
                                                <CheckCircleOutlineIcon className={`text-${colorClass} me-2 flex-shrink-0 mt-1`} style={{ fontSize: '1.2rem' }} />
                                                <span>Sujets d'actualités d'expression orale</span>
                                            </li>

                                            <li className="mb-3 d-flex align-items-start">
                                                <CheckCircleOutlineIcon className={`text-${colorClass} me-2 flex-shrink-0 mt-1`} style={{ fontSize: '1.2rem' }} />
                                                <span>Sujets d'actualités d'expression écrite</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="pricing-footer p-4 text-center mt-auto">
                                        <button
                                            className={`btn btn-${colorClass} btn-lg w-100 subscribe-btn`}
                                            onClick={(e) => handleSubscription(e, index)}
                                        >
                                            M'abonner
                                            <ArrowForwardIcon className="ms-2" style={{ fontSize: '1.1rem' }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {offers.length === 0 && (
                    <div className="text-center py-4">
                        <p className="text-muted">Aucune offre disponible pour le moment.</p>
                    </div>
                )}
            </div>

            {/* Utility Components */}
            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

            <QuestionModal
                show={show}
                setShow={setShow}
                title={title}
                question={question}
                setModalValue={setModalValue}
            />

            {/* CSS Styles */}
            <style jsx>{`
        .pricing-section {
          background-color: #f8f9fa;
          position: relative;
        }
        
        .pricing-section::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 300px;
          height: 300px;
          background: radial-gradient(circle, rgba(13, 110, 253, 0.05) 0%, rgba(13, 110, 253, 0) 70%);
          border-radius: 50%;
          z-index: 0;
        }
        
        .section-title {
          font-size: 2.25rem;
          font-weight: 700;
          color: #212529;
          margin-bottom: 1rem;
        }
        
        .pricing-card {
          background-color: white;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease;
          position: relative;
          display: flex;
          flex-direction: column;
          border: 1px solid rgba(0, 0, 0, 0.08);
        }
        
        .pricing-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        }
        
        .pricing-card.featured {
          transform: scale(1.02);
          z-index: 1;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          border: 2px solid var(--bs-primary);
        }
        
        .pricing-card.featured:hover {
          transform: scale(1.02) translateY(-5px);
        }
        
        .popular-badge {
          position: absolute;
          top: 15px;
          right: 0;
          background-color: var(--bs-primary);
          color: white;
          font-size: 0.7rem;
          font-weight: 600;
          padding: 0.25rem 1rem;
          border-radius: 4px 0 0 4px;
          letter-spacing: 1px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          z-index: 1;
        }
        
        .pricing-title {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }
        
        .pricing-price {
          margin-top: 1.5rem;
          margin-bottom: 1rem;
        }
        
        .price-value {
          font-size: 2.5rem;
          line-height: 1;
        }
        
        .currency {
          font-size: 1rem;
          font-weight: 500;
          margin-left: 0.25rem;
          opacity: 0.8;
        }
        
        .price-xaf {
          font-size: 0.9rem;
          opacity: 0.7;
          font-weight: 500;
        }
        
        .duration {
          font-size: 0.9rem;
          opacity: 0.7;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .pricing-features ul li {
          margin-bottom: 0.75rem;
          display: flex;
          align-items: flex-start;
        }
        
        .pricing-features ul li:last-child {
          margin-bottom: 0;
        }
        
        .subscribe-btn {
          transition: all 0.3s ease;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .subscribe-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        
        @media (max-width: 992px) {
          .pricing-card.featured {
            transform: scale(1);
          }
          
          .pricing-card.featured:hover {
            transform: translateY(-5px);
          }
        }
      `}</style>
        </section>
    );
};

export default Pricing;