import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import { NavLink } from 'react-router-dom';


const P_About = () => {

    return (
        <>
            <Menu />
            <div style={{ marginTop: '8rem' }}></div>
            <div className='mx-0 row d-flex justify-content-center mt-7 mt-md-8 mb-4'>
                <div className="container mt-5 col-md-6 col-11 col-sm-11 privacy-page" >
                    <h1 className="text-center text-uppercase fs-2 fs-sm-4 fs-md-5 mb-3">À Propos de notre Application de Préparation au TCF Canada</h1>
                    <p>Bienvenue sur notre application de préparation au Test de Connaissance du Français (TCF) Canada ! Notre plateforme a été spécialement conçue pour vous aider à vous préparer de manière efficace et réussir le TCF Canada avec confiance.</p>
                    <h2>Notre Mission :</h2>
                    <p>Notre mission est de mettre à votre disposition des ressources de haute qualité qui vous permettront de maîtriser chaque aspect de l'examen du TCF Canada. Qu'il s'agisse de l'épreuve de compréhension orale, de la compréhension écrite, de l'expression orale ou de l'expression écrite, nous avons rassemblé des conseils pratiques, des astuces et des exercices ciblés pour vous aider à exceller.</p>
                    <h2>Pourquoi Choisir Notre Application :</h2>
                    <ul>
                        <li><strong>Ressources Exhaustives :</strong> Nous proposons une gamme complète de ressources pour chaque épreuve du TCF Canada, vous aidant à vous familiariser avec le format de l'examen et à renforcer vos compétences.</li>
                        <li><strong>Conseils de Professionnels :</strong> Nos conseils sont basés sur l'expérience de professionnels ayant une connaissance approfondie de l'examen. Nous vous guidons étape par étape pour une préparation stratégique.</li>
                        <li><strong>Pratique Intensive :</strong> Nous croyons en la pratique régulière. Notre application propose des exercices pratiques variés pour que vous puissiez vous exercer dans des conditions similaires à celles de l'examen réel.</li>
                        <li><strong>Accessible en Ligne :</strong> Vous pouvez accéder à notre application depuis n'importe quel appareil connecté à Internet, ce qui vous permet de vous entraîner quand et où vous le souhaitez.</li>
                    </ul>
                    <h2>Notre Équipe :</h2>
                    <p>Nous sommes une équipe passionnée de professionnels de l'enseignement et de l'apprentissage des langues. Nous comprenons les défis que représente le TCF Canada et sommes déterminés à vous fournir les meilleurs outils pour vous aider à réussir.</p>
                    <h2>Contactez-Nous :</h2>
                    <p>Si vous avez des questions, des suggestions ou des commentaires, n'hésitez pas à nous contacter. Nous sommes là pour vous aider dans votre parcours de préparation au TCF Canada. Vous pouvez nous joindre via notre <NavLink to={'/contactez-nous'}>page de contact</NavLink>.</p>
                </div>

            </div>

            <Footer />

        </>
    );
};

export default P_About;