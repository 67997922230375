import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import MyToast from '../components/MyToast';
import MySpinner from '../components/MySpinner';
import { sendMailWithMailJet } from '../services/api';

const P_ContactsUs = () => {

    const data = { email: '', subject: '', name: '', message:'' };

    const [contactData, setContactData] = React.useState(data);
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    const handleChange = e => {
        setContactData({ ...contactData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const { email, subject, name, message } = contactData;
        if (!email || email === '' || !message || message === '') {
            setOpenToast({
                open: true,
                msg: 'Entrer votre adresse email et un message',
                severity: 'error'
            })
            return;
        }

        setinProgress(true);

        try {
            await sendMailWithMailJet({
                from: 'services@exams-tcfcanada.com',
                to_email: `mfi.tene@gmail.com`,
                from_name: 'Contactez-moi',
                subject: subject,
                text: message + `\n\nNom: ${name}\nEmail: ${email}`
            });

            setOpenToast({
                open: true,
                msg: 'Votre message a été envoyé avec succès',
                severity: 'success'
            })
            setinProgress(false);
            setContactData(data);
            return;

        } catch (error) {
            console.log(error);
            setOpenToast({
                open: true,
                msg: 'Une erreur s\'est produite lors de l\'envoi du mail. Veuillez réessayer ultérieurement',
                severity: 'error'
            })
            setinProgress(false);
            return;
        }
    }

    return (
        <>
            <Menu />
            <div style={{ marginTop: '8rem' }}></div>
            <section className="ftco-section mx-0 px-0 mt-md-7">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="wrapper">
                                <div className="row no-gutters">
                                    <div className="col-md-7 d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Envoyez nous un message</h3>

                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" onChange={handleChange} value={contactData.name} className="form-control" name="name" id="name" placeholder="Nom" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="email" onChange={handleChange} value={contactData.email} className="form-control" required name="email" id="email" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="text" onChange={handleChange} value={contactData.subject} className="form-control" name="subject" id="subject" placeholder="Objet" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea name="message" onChange={handleChange} value={contactData.message} required className="form-control" id="message" cols={30} rows={7} placeholder="Message" defaultValue={""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="submit" defaultValue="Envoyer le message" className="btn btn-primary" />
                                                            <div className="submitting" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap h-auto w-100 p-lg-5 p-4" style={{backgroundColor: '#666666'}}>
                                            <h3 className="mb-4 mt-md-4">Contactez-nous</h3>
                                            <div className="dbox w-100 d-flex align-items-start">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-map-marker" />
                                                </div>
                                                <div className="text pl-3 mt-3">
                                                    <p><span>Adresse:</span> Douala-CMR</p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-paper-plane" />
                                                </div>
                                                <div className="text pl-3">
                                                    <p><span>Email:</span> <a href="mailto:info@yoursite.com">services@exams-tcfcanada.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

            <div style={{ marginBottom: '5rem' }}></div>
            <Footer />
        </>
    );
};

export default P_ContactsUs;