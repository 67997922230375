import Footer from '../components/Footer';
import Menu from '../components/Navbar';

const P_Privacy = () => {

    return (
        <>
            <Menu />
            <div style={{ marginTop: '8rem' }}></div>
            <div className='mx-0 row d-flex justify-content-center mt-7 mt-md-8 mb-4'>
                <div className="container mt-5 col-md-6 col-11 col-sm-11 privacy-page">
                    <h1 className="mb-4 text-center text-uppercase">Politique de Confidentialité</h1>
                    <p>Nous, chez Exams-TCFCanada, nous attachons une grande importance à la confidentialité de nos utilisateurs. Cette Politique de Confidentialité vise à vous informer sur la manière dont nous collectons, utilisons, partageons et protégeons vos informations personnelles lorsque vous utilisez notre site web et nos services associés.</p>
                    <h2>1. Collecte d'Informations</h2>
                    <p>Nous pouvons collecter des informations personnelles vous concernant lorsque vous vous inscrivez, achetez des abonnements ou interagissez avec notre site web. Les informations collectées peuvent inclure votre nom, adresse e-mail, numéro de téléphone, adresse et informations de paiement.</p>
                    <h2>2. Utilisation des Informations</h2>
                    <p>Nous utilisons les informations collectées pour vous fournir nos services, y compris la préparation aux examens TCF Canada, la gestion des abonnements et la communication avec vous. Vos informations peuvent également être utilisées pour améliorer nos services, personnaliser votre expérience utilisateur et vous informer des mises à jour pertinentes.</p>
                    {/* ... Continuer avec les autres sections ... */}
                    <h2>6. Mineurs</h2>
                    <p>Notre site web n'est pas destiné aux personnes de moins de 18 ans. Si nous apprenons que nous avons collecté des informations personnelles d'un mineur, nous prendrons des mesures pour supprimer ces informations.</p>
                    <h2>7. Modifications de la Politique de Confidentialité</h2>
                    <p>Nous pouvons mettre à jour cette Politique de Confidentialité de temps en temps. Les modifications seront affichées sur cette page et la date de mise à jour sera modifiée en conséquence. Nous vous encourageons à consulter régulièrement cette page pour rester informé de nos pratiques en matière de confidentialité.</p>
                    <p>Pour toute question ou préoccupation concernant notre Politique de Confidentialité, veuillez nous contacter à l'adresse e-mail <a href="mailto:services@exams-tcfcanada.com">services@exams-tcfcanada.com</a> .</p>
                    <p>Merci d'utiliser Exams-TCFCanada pour votre préparation au TCF Canada.</p>
                </div>

                <div style={{ marginBottom: '5rem' }}></div>

            </div>
            <Footer />
        </>
    );
};

export default P_Privacy;