import * as React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";

// Material UI imports
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

// Icons
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalculateIcon from '@mui/icons-material/Calculate';

// Components
import MyToast from './MyToast';
import MySpinner from './MySpinner';

// Styled components
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const NavIcon = ({ icon: IconComponent, color, text }) => (
    <div className="d-flex align-items-center">
        <IconComponent className={`text-${color} mr-2`} style={{ fontSize: '1.2rem' }} />
        <span>{text}</span>
    </div>
);

const PromoBar = () => (
    <div className='promo-bar' style={{
        width: '100%',
        background: '#005800',
        position: 'fixed',
        top: 0,
        zIndex: 1000,
        padding: '5px 30px',
        textAlign: 'center',
        color: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
        <p style={{ fontSize: '0.75em', lineHeight: '1.2', margin: 0 }}>
            <a
                href="https://rheamartech-immigration.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    color: 'white',
                    display: 'block',
                    textDecoration: 'none',
                    fontWeight: 500,
                    transition: 'all 0.2s ease'
                }}
                onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
                Cliquez ici pour découvrir notre service d'accompagnement pour votre projet d'immigration au Canada.
            </a>
        </p>
    </div>
);

function Menu() {
    const navigate = useNavigate();
    const [user, setUser] = React.useState(null);
    const [inProgress, setInProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        setInProgress(true);
        signOut(auth)
            .then(() => {
                setInProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Déconnexion réussie',
                    severity: 'success'
                });
                navigate("/accueil");
            })
            .catch((error) => {
                setInProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement svp',
                    severity: 'error'
                });
            });
    };

    const handleNavigation = (path) => (e) => {
        e.preventDefault();
        navigate(path);
    };

    const isAdmin = user?.email === 'mfi.tene@gmail.com' || user?.email === 'tefuncowm@gmail.com';
    const userName = user?.name || "Compte";
    
    return (
        <>
            <PromoBar />

            <Navbar
                collapseOnSelect
                expand="md"
                fixed="top"
                variant="dark"
                className="py-2 mt-4"
                style={{ boxShadow: '0 2px 10px rgba(0,0,0,0.2)', backgroundColor: "#2b3035"}}
            >
                <Container>
                    <Navbar.Brand as={NavLink} to="/accueil" className="d-flex align-items-center">
                        <img
                            src='../assets/img/logo.png'
                            alt='EXAMS-TCFCANADA'
                            className="brand-logo"
                            style={{
                                width: 'auto',
                                height: '42px',
                                transition: 'transform 0.3s ease'
                            }}
                        />
                    </Navbar.Brand>

                    {user && (
                        <div className="d-md-none mr-2">
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Avatar
                                    sx={{ width: 32, height: 32 }}
                                    alt={userName}
                                />
                            </StyledBadge>
                        </div>
                    )}

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto mt-md-0 mt-3">
                            <Nav.Link
                                onClick={handleNavigation('/sujets-comprehension-orale')}
                                className="nav-link-custom py-2"
                            >
                                <NavIcon icon={HeadphonesIcon} color="info" text="COMPRÉHENSION ORALE" />
                            </Nav.Link>

                            <Nav.Link
                                onClick={handleNavigation('/sujets-comprehension-ecrite')}
                                className="nav-link-custom py-2"
                            >
                                <NavIcon icon={MenuBookOutlinedIcon} color="danger" text="COMPRÉHENSION ÉCRITE" />
                            </Nav.Link>

                            <Nav.Link
                                onClick={handleNavigation('/sujets-expression-orale')}
                                className="nav-link-custom py-2"
                            >
                                <NavIcon icon={PermCameraMicIcon} color="success" text="EXPRESSION ORALE" />
                            </Nav.Link>

                            <Nav.Link
                                onClick={handleNavigation('/sujets-expression-ecrite')}
                                className="nav-link-custom py-2"
                            >
                                <NavIcon icon={KeyboardIcon} color="warning" text="EXPRESSION ÉCRITE" />
                            </Nav.Link>

                            <Nav.Link
                                onClick={handleNavigation('/calculatrice-nclc')}
                                className="nav-link-custom py-2 d-md-none"
                            >
                                <NavIcon icon={CalculateIcon} color="secondary" text="CALCULATRICE NCLC" />
                            </Nav.Link>

                            <Nav.Link
                                onClick={handleNavigation('/offres')}
                                className="nav-link-custom py-2 d-md-none"
                            >
                                <NavIcon icon={CalculateIcon} color="secondary" text="NOS OFFRES" />
                            </Nav.Link>

                            {/* Mobile menu authentication options */}
                            <div className="d-md-none mt-3">
                                <Divider className="my-2" />

                                {user ? (
                                    <>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/abonnement-historique"
                                            className="text-white py-2"
                                        >
                                            MES ABONNEMENTS
                                        </Nav.Link>

                                        {isAdmin && (
                                            <Nav.Link
                                                as={NavLink}
                                                to="/administration?service=1"
                                                className="text-white py-2"
                                            >
                                                ADMINISTRATION
                                            </Nav.Link>
                                        )}

                                        <Nav.Link
                                            as={NavLink}
                                            to="/modifier-mot-passe"
                                            className="text-white py-2"
                                        >
                                            CHANGER DE MOT DE PASSE
                                        </Nav.Link>

                                        <Nav.Link
                                            onClick={handleLogout}
                                            className="text-white py-2"
                                        >
                                            DÉCONNEXION
                                        </Nav.Link>
                                    </>
                                ) : (
                                    <>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/connexion"
                                            className="text-white py-2"
                                        >
                                            CONNEXION
                                        </Nav.Link>

                                        <Nav.Link
                                            as={NavLink}
                                            to="/inscription"
                                            className="text-white py-2"
                                        >
                                            INSCRIPTION
                                        </Nav.Link>
                                    </>
                                )}
                            </div>
                        </Nav>

                        {/* Desktop authentication menu */}
                        <Nav className="d-none d-md-flex align-items-center">
                            {user ? (
                                <div className="d-flex align-items-center">
                                    <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        variant="dot"
                                    >
                                        <Avatar
                                            alt={userName}
                                            src="/broken-image.jpg"
                                            sx={{
                                                width: 36,
                                                height: 36,
                                                marginRight: '8px',
                                                border: '2px solid rgba(255,255,255,0.2)'
                                            }}
                                        />
                                    </StyledBadge>

                                    <NavDropdown
                                        title={<span className="text-white">{userName}</span>}
                                        id="user-dropdown"
                                        align="end"
                                    >
                                        <NavDropdown.Item onClick={handleNavigation('/abonnement-historique')}>
                                            Mes abonnements
                                        </NavDropdown.Item>

                                        {isAdmin && (
                                            <NavDropdown.Item onClick={handleNavigation('/administration?service=1')}>
                                                Administration
                                            </NavDropdown.Item>
                                        )}

                                        <NavDropdown.Item onClick={handleNavigation('/modifier-mot-passe')}>
                                            Changer de mot de passe
                                        </NavDropdown.Item>

                                        <NavDropdown.Divider />

                                        <NavDropdown.Item onClick={handleLogout}>
                                            Déconnexion
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            ) : (
                                <div className="d-flex">
                                    <Nav.Link
                                        as={NavLink}
                                        to="/connexion"
                                        className="auth-btn mr-2"
                                        style={{
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '0.5rem 1rem',
                                            transition: 'all 0.2s ease'
                                        }}
                                    >
                                        CONNEXION
                                    </Nav.Link>

                                    <Nav.Link
                                        as={NavLink}
                                        to="/inscription"
                                        className="auth-btn"
                                        style={{
                                            backgroundColor: '#005800',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '0.5rem 1rem',
                                            marginLeft: '8px',
                                            transition: 'all 0.2s ease'
                                        }}
                                    >
                                        INSCRIPTION
                                    </Nav.Link>
                                </div>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <style jsx>{`
        .nav-link-custom:hover {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          transition: all 0.2s ease;
        }
        
        .auth-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        
        .brand-logo:hover {
          transform: scale(1.05);
        }
      `}</style>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />
        </>
    );
}

export default Menu;