import React, { useEffect } from 'react';
import Menu from '../components/Navbar';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import Cover from '../components/Cover';
import SubjectPresentation from '../components/SubjectPresentation';
import CustomTitle from '../components/CustomTitle';
import Pricing from '../components/Pricing';
import CalculatriceNcLc from '../components/CalculatriceNcLc';
import ReactGA from 'react-ga';
import '../css/vitrineImmigration.css';
import { useNavigate } from 'react-router-dom';
import Testimonials from '../components/UserReview';

const P_Home = () => {

    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.pageview('/accueil');

        // Commencez à mesurer la durée de session lorsque l'utilisateur accède à la page
        ReactGA.event({
            category: 'Session',
            action: 'Start',
            label: 'accueil'
        });

        return () => {
            // Terminez la mesure de la durée de session lorsque l'utilisateur quitte la page
            ReactGA.event({
                category: 'Session',
                action: 'End',
                label: 'accueil'
            });
        }
    }, []);

    return (
        <>
            <Menu />
            <div style={{ marginTop: '5rem' }}></div>
            <Cover />
            <Container className=''>
                <SubjectPresentation />
                <CustomTitle
                    part1={'NOS FORFAITS '}
                    part2={'POUR VOUS FORMER'}
                    part3={' CHEZ NOUS'}
                    description={"Faites vos choix, abonnez-vous, et commencez aujourd'hui"}
                />
                <div style={{ marginTop: '2rem' }}></div>
                <Pricing />
                <div style={{ marginTop: '5rem' }}></div>

                {/* <CustomTitle
                    part1={'CREER UN COMPTE '}
                    part2={'GRATUITEMENT'}
                    part3={' SUR NOTRE PLATEFORME'}
                    description={"Vous avez besoin d'un compte pour pouvoir vous abonner à une offre"}
                /> */}

                {/*  <SignUp /> */}

                {/* <div style={{ marginTop: '4rem' }}></div> */}


                <div className="service-section py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="service-card position-relative overflow-hidden">
                                    {/* Background decorative elements */}
                                    <div className="service-bg-shape shape-1"></div>
                                    <div className="service-bg-shape shape-2"></div>

                                    <div className="service-content p-4 p-md-5 position-relative">
                                        <div className="text-center text-md-start mb-4">
                                            <span className="service-badge">Service Premium</span>
                                            <h2 className="service-title mt-3">Services de consultation en ligne pour l'immigration au Canada</h2>

                                            <div className="service-divider my-4 mx-auto mx-md-0"></div>

                                            <p className="service-description">
                                                Vous souhaitez immigrer au Canada avec votre famille, mais vous ne savez pas par où commencer?
                                                Nous pouvons vous guider à distance grâce à notre service Premium de Consultation en Immigration
                                                pour le Canada. Pour obtenir davantage d'informations sur cette offre et y souscrire,
                                                veuillez cliquer sur le bouton ci-dessous.
                                            </p>
                                        </div>

                                        <div className="text-center text-md-start">
                                            <a
                                                href="https://rheamartech-immigration.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="service-button"
                                            >
                                                <span>En savoir plus</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <style jsx>{`
    .service-section {
      background-color: #f8f9fa;
      position: relative;
    }
    
    .service-card {
      background: linear-gradient(to right, #ffffff, #f8f9fa);
      border-radius: 12px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
      transition: all 0.3s ease;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
    
    .service-card:hover {
      box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
      transform: translateY(-5px);
    }
    
    .service-bg-shape {
      position: absolute;
      border-radius: 50%;
      z-index: 0;
    }
    
    .shape-1 {
      top: -50px;
      right: -50px;
      width: 200px;
      height: 200px;
      background: radial-gradient(circle, rgba(41, 128, 185, 0.07) 0%, rgba(41, 128, 185, 0) 70%);
    }
    
    .shape-2 {
      bottom: -30px;
      left: -30px;
      width: 150px;
      height: 150px;
      background: radial-gradient(circle, rgba(46, 204, 113, 0.07) 0%, rgba(46, 204, 113, 0) 70%);
    }
    
    .service-badge {
      display: inline-block;
      padding: 0.5rem 1rem;
      background-color: rgba(52, 152, 219, 0.1);
      color: #3498db;
      font-weight: 600;
      font-size: 0.85rem;
      border-radius: 30px;
    }
    
    .service-title {
      font-size: 2rem;
      font-weight: 700;
      color: #2c3e50;
      line-height: 1.3;
    }
    
    .service-divider {
      width: 80px;
      height: 4px;
      background: linear-gradient(to right, #3498db, #2ecc71);
      border-radius: 2px;
    }
    
    .service-description {
      color: #7f8c8d;
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 2rem;
    }
    
    .service-button {
      display: inline-flex;
      align-items: center;
      padding: 0.75rem 1.5rem;
      background-color: #3498db;
      color: white;
      font-weight: 600;
      border-radius: 8px;
      text-decoration: none;
      transition: all 0.3s ease;
      box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
    }
    
    .service-button:hover {
      background-color: #2980b9;
      transform: translateY(-2px);
      box-shadow: 0 6px 15px rgba(52, 152, 219, 0.4);
      color: white;
    }
    
    .service-button svg {
      margin-left: 8px;
      transition: transform 0.3s ease;
    }
    
    .service-button:hover svg {
      transform: translateX(5px);
    }
    
    @media (max-width: 768px) {
      .service-title {
        font-size: 1.75rem;
      }
      
      .service-description {
        font-size: 1rem;
      }
    }
  `}</style>
                </div>


                <div style={{ marginTop: '5rem' }}></div>



                <div style={{ marginTop: '4rem' }}></div>

                <Testimonials />


                {/* <CustomTitle
                    part1={''}
                    part2={'RESULTATS DE NOS CANDIDATS '}
                    part3={''}
                    description={"Bonjour le monde"}
                /> */}

                {/* <CustomCarousel /> */}
                {/* <ImageCarousel /> */}
                {/*  <div style={{ marginTop: '4rem' }}></div> */}

                <CustomTitle
                    part1={'CALCULER VOTRE SCORE NCLC '}
                    part2={'GRATUITEMENT'}
                    part3={' SUR NOTRE PLATEFORME'}
                />

                <CalculatriceNcLc />

            </Container>
            <div style={{ marginTop: '2rem' }}></div>
            <Footer />
        </>
    );
};

export default P_Home;