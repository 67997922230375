import React from 'react';
import Menu from '../components/Navbar';
import SubjectTitleSecond from '../components/SubjectTitleSecond';
import Divider from '@mui/material/Divider';
import SubjectsList from '../components/SubjectsList';
import Footer from '../components/Footer';
import { listConstantes } from '../services/api';
import Container from 'react-bootstrap/Container';
import CustomTitle from '../components/CustomTitle';
import Pricing from '../components/Pricing';

const P_CE_Subject = () => {

    return (
        <>
            <Menu />
            <div style={{ marginTop: '5rem' }}></div>
            <SubjectTitleSecond
                title={'COMPRÉHENSION ÉCRITE'}
                subTitle={'PRO'}
                description={'Plus de 1130 questions de compréhension écrite pour vous permettre de préparer votre examen.'}
                img={"../assets/img/cover-question1.jpg"}
            />
            <Container className='mt-6'>
                <div style={{ marginTop: '2rem' }}></div>
                <span className='customDivider'>
                    <Divider className='text-capitalize'>Liste des sujets</Divider>
                </span>
                <div style={{ marginTop: '2rem' }}></div>
                <SubjectsList
                    subjectTypeID={listConstantes().CE.id}
                    subjectName={listConstantes().CE.name}
                    redirect={'/sujet-comprehension'}
                    subjectCode={'CE'}
                />
                <div style={{ marginTop: '2rem' }}></div>

                <CustomTitle
                    part1={'NOS FORFAITS '}
                    part2={'POUR VOUS FORMER'}
                    part3={' CHEZ NOUS'}
                    description={"Faites vos choix, abonnez-vous, et commencez aujourd'hui"}
                />
                <div style={{ marginTop: '2rem' }}></div>
                <Pricing />
                <div style={{ marginTop: '4rem' }}></div>

            </Container>
            <Footer />
        </>
    );
};

export default P_CE_Subject;