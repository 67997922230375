import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";
import { useEffect } from 'react';
import MyToast from '../components/MyToast';
import MySpinner from '../components/MySpinner';
import { updatePassword, getAuth, reauthenticateWithCredential, EmailAuthProvider} from 'firebase/auth';


const P_ChangePassword = () => {

    const navigate = useNavigate();

    const [password, setPassword] = React.useState('');
    const [cfpassword, setcfPassword] = React.useState('');
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!password || !cfpassword || !currentPassword) {
            setOpenToast({
                open: true,
                msg: "Veuillez renseigner tous les champs !",
                severity: "error"
            })
            return;
        }

        if (password.length < 6) {
            setOpenToast({
                open: true,
                msg: "Le mot de passe doit voir au moins 6 caractères",
                severity: "error"
            })
            return;
        }

        if (password !== cfpassword) {
            setOpenToast({
                open: true,
                msg: "Les mots de passe ne correspondent pas",
                severity: "error"
            })
            return;
        }

        const user = getAuth().currentUser;

        if (!user) {
            navigate('/connexion')
            return;
        }

        console.log(user);

        var cred = EmailAuthProvider.credential(user.email, currentPassword);

        try {

            setinProgress(true);

            await reauthenticateWithCredential(user, cred);
            await updatePassword(user, password);

            setPassword('');
            setcfPassword('');
            setCurrentPassword('');

            setOpenToast({
                open: true,
                msg: "Votre mot de passe a été modifié avec success",
                severity: "success"
            })

            setinProgress(false);

            return;

        } catch (error) {
            setinProgress(false);
            const errorCode = error?.code;
            const errorMessage = error?.message;

            if (errorCode == 'auth/email-already-in-use') {
                setOpenToast({
                    open: true,
                    msg: "Cette adresse email est déjà utilisée",
                    severity: "error"
                })
                return;
            }

            else if(errorCode === 'auth/wrong-password') {
                setOpenToast({
                    open: true,
                    msg: "Le mot de passe est invalide",
                    severity: "error"
                })
                return;
            }

            else if (errorCode === 'auth/network-request-failed') {
                setOpenToast({
                    open: true,
                    msg: "Vérifiez votre connexion internet",
                    severity: "error"
                })
                return;
            }

            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
                return;
            }
        }

    };

    return (
        <>
            <Menu />

            <div style={{ marginTop: '8rem' }}></div>
            <div className="row mx-0 px-0">
                <div className="col-sm-1 col-md-2 col-lg-3 col-xxl-4"></div>
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-4">

                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <h5 className="text-center">Modification du mot de passe</h5>
                            <form className="mt-4" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input className="form-control" type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} name="currentPassword" id="currentPassword" placeholder="Mot de passe actuel" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="password" value={password} onChange={e => setPassword(e.target.value)} name="password" id="password" placeholder="Nouveau mot de passe" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="password" value={cfpassword} onChange={e => setcfPassword(e.target.value)} name="cfpassword" id="cfpassword" placeholder="Confirmer le mot de passe" />
                                </div>
                                <button className="btn btn-block mt-3" style={{backgroundColor: '#2b3035', color: 'white'}} type="submit" name="submit">Modifier le mot de passe</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ marginBottom: '5rem' }}></div>

            {password} {cfpassword}

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

            <Footer />
        </>
    );
};

export default P_ChangePassword;