import React from 'react';
import { NavLink } from 'react-router-dom';

// Icons
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';

const SubjectCard = ({
    icon: IconComponent,
    iconColor,
    title,
    description,
    articlePath,
    practicePath,
    buttonText,
    buttonColor
}) => {
    return (
        <div className="subject-card h-100">
            <NavLink to={practicePath} className="card-link">
                <div className="card h-100 border-0 shadow-sm hover-card">
                    <div className="card-icon-wrapper">
                        <IconComponent
                            className={`text-${iconColor}`}
                            style={{ fontSize: '3.5rem' }}
                        />
                        <div className="icon-background"></div>
                    </div>

                    <div className="card-body d-flex flex-column">
                        <h4 className="card-title fw-bold mb-3">{title}</h4>

                        <p className="card-text flex-grow-1">
                            {description}
                            <NavLink
                                to={articlePath}
                                className={`d-inline-block text-${iconColor} mt-2 learn-more-link`}
                            >
                                En savoir davantage <ArrowForwardIcon style={{ fontSize: '0.9rem', verticalAlign: 'middle' }} />
                            </NavLink>
                        </p>

                        <div className="mt-auto">
                            <NavLink
                                to={practicePath}
                                className={`btn btn-${buttonColor} rounded-pill px-4 py-2 fw-medium practice-button`}
                            >
                                <SportsMartialArtsIcon className="me-2" style={{ fontSize: '1.1rem' }} />
                                {buttonText}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

const SubjectPresentation = () => {
    const subjects = [
        {
            icon: HeadphonesIcon,
            iconColor: 'info',
            title: 'Compréhension Orale',
            description: 'L\'épreuve de compréhension orale au TCF Canada évalue la capacité des candidats à comprendre et à interpréter des enregistrements audio en français.',
            articlePath: '/article-comprehension-orale',
            practicePath: '/sujets-comprehension-orale',
            buttonText: 'M\'entraîner',
            buttonColor: 'outline-info'
        },
        {
            icon: MenuBookOutlinedIcon,
            iconColor: 'danger',
            title: 'Compréhension Écrite',
            description: 'L\'épreuve de compréhension écrite au TCF Canada vise à évaluer la capacité des candidats à comprendre et à interpréter des textes écrits en français.',
            articlePath: '/article-comprehension-ecrite',
            practicePath: '/sujets-comprehension-ecrite',
            buttonText: 'M\'entraîner',
            buttonColor: 'outline-danger'
        },
        {
            icon: PermCameraMicIcon,
            iconColor: 'success',
            title: 'Expression Orale',
            description: 'Cette épreuve évalue la capacité des candidats à déterminer le niveau de maîtrise du français dans des situations de la vie quotidienne.',
            articlePath: '/article-expression-orale',
            practicePath: '/sujets-expression-orale',
            buttonText: 'Voir les sujets',
            buttonColor: 'outline-success'
        },
        {
            icon: KeyboardIcon,
            iconColor: 'warning',
            title: 'Expression Écrite',
            description: 'L\'épreuve d\'Expression écrite vise à évaluer l\'aptitude des candidats à exprimer des idées de manière claire et cohérente, à développer des arguments et à utiliser correctement la langue française.',
            articlePath: '/article-expression-ecrite',
            practicePath: '/sujets-expression-ecrite',
            buttonText: 'Voir les sujets',
            buttonColor: 'outline-warning'
        }
    ];

    return (
        <section className="subject-presentation py-5" id="start">
            <div className="container">
                <div className="section-header text-center mb-5">
                    <div className="subtitle mb-3">Notre programme de préparation</div>
                    <h2 className="section-title fw-bold mb-3">PRÉPARATION AU TCF CANADA</h2>
                    <div className="title-underline"></div>
                    <p className="section-description mt-4 mx-auto">
                        Nous vous proposons des sujets tirés de l'examen du TCF Canada pour optimiser votre préparation
                    </p>
                </div>

                <div className="row g-4">
                    {subjects.slice(0, 3).map((subject, index) => (
                        <div className="col-md-6 col-lg-4" key={index}>
                            <SubjectCard {...subject} />
                        </div>
                    ))}
                </div>

                <div className="row g-4 mt-2 justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <SubjectCard {...subjects[3]} />
                    </div>
                </div>
            </div>

            <style jsx>{`
        .subject-presentation {
          background-color: #f8f9fa;
          position: relative;
          overflow: hidden;
        }
        
        .subject-presentation::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 300px;
          height: 300px;
          background: radial-gradient(circle, rgba(13, 110, 253, 0.05) 0%, rgba(13, 110, 253, 0) 70%);
          border-radius: 50%;
          z-index: 0;
        }
        
        .subject-presentation::after {
          content: '';
          position: absolute;
          bottom: -100px;
          left: -100px;
          width: 400px;
          height: 400px;
          background: radial-gradient(circle, rgba(220, 53, 69, 0.05) 0%, rgba(220, 53, 69, 0) 70%);
          border-radius: 50%;
          z-index: 0;
        }
        
        .section-header {
          position: relative;
          z-index: 1;
        }
        
        .subtitle {
          font-size: 1rem;
          color: #6c757d;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: 500;
        }
        
        .section-title {
          font-size: 2.25rem;
          color: #212529;
          letter-spacing: -0.5px;
        }
        
        .title-underline {
          width: 80px;
          height: 4px;
          background: linear-gradient(90deg, #0d6efd, #dc3545);
          margin: 0 auto;
          border-radius: 2px;
        }
        
        .section-description {
          font-size: 1.1rem;
          color: #6c757d;
          max-width: 650px;
          line-height: 1.6;
        }
        
        .subject-card {
          position: relative;
          z-index: 1;
          transition: all 0.3s ease;
          height: 100%;
        }
        
        .card-link {
          text-decoration: none;
          color: inherit;
          display: block;
          height: 100%;
        }
        
        .hover-card {
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          border-radius: 12px;
          overflow: hidden;
        }
        
        .hover-card:hover {
          transform: translateY(-8px);
          box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
        }
        
        .card-icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 120px;
          position: relative;
        }
        
        .icon-background {
          position: absolute;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: rgba(0,0,0,0.03);
          z-index: -1;
        }
        
        .card-body {
          padding: 1.75rem;
        }
        
        .card-title {
          font-size: 1.4rem;
          color: #212529;
          position: relative;
          display: inline-block;
        }
        
        .card-text {
          color: #6c757d;
          line-height: 1.6;
        }
        
        .learn-more-link {
          text-decoration: none;
          font-weight: 500;
          transition: all 0.2s ease;
        }
        
        .learn-more-link:hover {
          text-decoration: underline;
        }
        
        .practice-button {
          transition: all 0.3s ease;
          border-width: 2px;
          font-weight: 500;
        }
        
        .practice-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        
        @media (max-width: 768px) {
          .section-title {
            font-size: 1.8rem;
          }
        }
      `}</style>
        </section>
    );
};

export default SubjectPresentation;