import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaQuoteLeft, FaStar, FaCheckCircle } from 'react-icons/fa';

const Testimonials = () => {
    const testimonials = [
        {
            quote: "Grâce à EXAMS TCF CANADA, j'ai obtenu 3 C2 et 1 C1 à mon examen. Les tests sont très proches de l'examen réel et m'ont parfaitement préparé.",
            initials: "SM",
            name: "Sophie M.",
            status: "Niveau NCLC 10 obtenu",
            verified: true
        },
        {
            quote: "Ce qui m'a vraiment impressionné, c'est la richesse des exercices d'écoute. Avec des milliers d'audios accompagnés de corrections, j'ai pu m'entraîner dans des conditions réelles. Cette immersion quotidienne a considérablement amélioré ma compréhension orale.",
            initials: "MB",
            name: "Maria B.",
            status: "NCLC 9 en compréhension orale",
            verified: true
        },
        {
            quote: "Cette application aide vraiment ceux qui n'ont pas les moyens de payer des cours particuliers. La correction IA pour mes sujets d'expression écrite m'a beaucoup aidé à améliorer mon style.",
            initials: "AM",
            name: "Aminata M.",
            status: "Entrée Express approuvée",
            verified: true
        }
    ];

    return (
        <section className="py-5 bg-light">
            <Container>
                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="fw-bold">Ce que nos utilisateurs disent</h2>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="bg-primary" style={{ height: '4px', width: '80px' }}></div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    {testimonials.map((testimonial, index) => (
                        <Col key={index} lg={4} md={6} className="mb-4">
                            <Card className="h-100 shadow-sm border-0 rounded-4 testimonial-card">
                                <Card.Body className="d-flex flex-column p-4">
                                    {/* Avatar et citation */}
                                    <div className="d-flex align-items-center mb-4">
                                        <div
                                            className="bg-primary text-white rounded-circle me-3 d-flex justify-content-center align-items-center"
                                            style={{ width: '48px', height: '48px', minWidth: '48px' }}
                                        >
                                            {testimonial.initials}
                                        </div>
                                        <div>
                                            <h5 className="fw-bold mb-0">{testimonial.name}</h5>
                                            <div className="d-flex align-items-center">
                                                <span className="text-muted small me-2">{testimonial.status}</span>
                                                {testimonial.verified && (
                                                    <FaCheckCircle className="text-success" size={14} />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Étoiles */}
                                    <div className="mb-3 text-warning">
                                        {[...Array(5)].map((_, i) => (
                                            <FaStar key={i} />
                                        ))}
                                    </div>

                                    {/* Citation */}
                                    <div className="position-relative">
                                        <FaQuoteLeft className="text-primary opacity-25 mb-2" size={24} />
                                        <p className="fst-italic">
                                            "{testimonial.quote}"
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {/* Call-to-action */}
                {/* <Row className="mt-5 text-center">
                    <Col>
                        <div className="bg-white p-4 rounded-4 shadow-sm d-inline-block">
                            <h4 className="mb-3">Prêt à réussir votre examen?</h4>
                            <button className="btn btn-primary btn-lg rounded-pill px-4">
                                Commencer ma préparation
                            </button>
                        </div>
                    </Col>
                </Row> */}
            </Container>

            {/* CSS personnalisé */}
            <style jsx>{`
        .testimonial-card {
          transition: transform 0.3s ease;
        }
        
        .testimonial-card:hover {
          transform: translateY(-5px);
        }
      `}</style>
        </section>
    );
};

export default Testimonials;